import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';
import AreaData from '../../Components/ServicesPage/AreaData'
import LocationData from '../../Components/LocationAll/LocationData'


export default function Footer({ WebSitePages, navigateTo }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer">
            <div className="Footer2">
                <div className="Footer2Logo" onClick={() => navigateTo("")}>
                    <h1><b>Rancho Cucamonga</b> Garage Door</h1>
                </div>
            </div>
            <div className="FooterTop">

                <div className="SiteMapDiv">
                    <h1>Rancho Cucamonga Garage Door</h1>
                    <div className="SiteMapDivList">
                        <a href="/">Home Page</a>
                        <a href="/book">Book Garage Door Appointment Online In Rancho Cucamonga, California</a>
                        <a href="/locations">Locations</a>
                        <a href={`/reviews/${LocationData.find(a => a.City == ("Rancho Cucamonga").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>Reviews</a>
                        <a href="/contact">Contact Us</a>
                        <a href="/blog">Blog</a>
                        <a href="/services">Services</a>
                        <a href="/faq">Faq</a>
                        <a href="/careers">Careers</a>
                        <a href="/sitemap">Site Map</a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Services</h1>
                    <div className="SiteMapDivList">
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Spring">Garage Door Broken Spring Rancho Cucamonga, CA</a>
                        {AreaData.map((A, index) =>
                            <a className="Header1BoxMenuBox" href={`${A.Path}`}>{A.Title}</a>
                        )}
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Contact Us</h1>
                    <div className="SiteMapDivList">
                        <a target='_blank' href="">
                            Rancho Cucamonga, California
                        </a>
                        <a target='_blank' href="tel:951-406-6196">
                            951-406-6196
                        </a>
                        <a target='_blank' href="mailto:info@ranchocucamongagaragedoor.org">
                            info@ranchocucamongagaragedoor.org
                        </a>
                    </div>
                </div>

            </div>
            <div className="Footer3">
                <h1>Copyright © 2023 Rancho Cucamonga Garage Door. All rights reserved.</h1>
            </div>
        </div>
    )
}
